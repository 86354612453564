import { useContext } from "react";
import { AuthContext } from "../context/auth";
import { useHistory } from "react-router-dom";
// Material Ui
import Box from "@material-ui/core/Box";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Button from "@material-ui/core/Button";
// User Defined
import Sidebar from "../components/homeDashboard/Sidebar";
import { challenges } from "../data/ChallengeData";
import NavigationChallengeCard from "../components/NavigationChallengeCard/";

function NavigationChallenges() {
  const { user, logout } = useContext(AuthContext);
  const history = useHistory();

  return (
    <Box
      component="section"
      className="mx-auto flex justify-between w-100 flex-col lg:flex-row relative"
    >
      <Sidebar user={user} logout={logout} />
      <Box className="pt-24 md:pt-10 2xl:pt-20 2xl:pb-28 pb-0 md:pb-10 2xl:px-10 pl-8 pr-2 ml-0 lg:ml-52 bg-no-repeat md:min-h-screen 2xl:h-screen bg-concrete overflow-auto">
        <h2
          variant="body1"
          component="p"
          className="text-emperor gesta-bold leading-tight mb-1 text-xl md:text-4xl"
        >
          The Seven Navigation Challenges
        </h2>
        <p
          component="p"
          variant="body1"
          className="text-black gesta-normal text-base leading-5 md:leading-6 md:text-lg pt-0 pb-6 md:pb-12"
        >
          Below are the seven areas in which you will be assessing your
          leadership. Please review before beginning your self audit.
        </p>
        <Box
          component="div"
          className="  flex w-full  flex-col sm:flex-row  flex-wrap justify-center sm:justify-start sm:items-start sm:items-stretch "
        >
          {challenges.map((currentData, i) => (
            <NavigationChallengeCard
              imgIcon={currentData.imgIcon}
              title={currentData.challenge}
              text={currentData.key_attr}
              color={currentData.color}
              number={i}
              key={i}
            />
            
          ))}
        </Box>
        <Box component="div" className="relative flex justify-between absolute md:static left-0 bottom-0 w-auto md:mx-0 -ml-8 -mr-2 md:w-full mt-2 md:mt-5 pr-0 md:pr-8">
          <Button
            onClick={() => history.push("/general-instruction")}
            variant="contained"
            className={`text-bigstone w-1/2 md:w-auto uppercase font-medium inline-flex items-center justify-center bg-alabaster gesta text-base md:text-lg rounded-none py-3.5 px-4 md:px-7 tracking-wider  `}
          >
            <KeyboardArrowLeftIcon className='mr-1.5' />
            Instructions
          </Button>
          <Button
            onClick={() => history.push("/challenges")}
            variant="contained"
            className={`bg-bigstone w-1/2 md:w-auto uppercase font-medium inline-flex items-center justify-center text-alabaster gesta text-base md:text-lg rounded-none py-3.5 px-4 md:px-7 tracking-wider`}
          >
            begin self-audit
            <KeyboardArrowRightIcon className='ml-1.5' />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default NavigationChallenges;

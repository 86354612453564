import { useContext, Fragment } from "react";
import { AuthContext } from "../context/auth";
import { Link } from "react-router-dom";
// Material Ui
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
// User Defined
import Sidebar from "../components/homeDashboard/Sidebar";
import ScrollTop from '../components/ScrollTop';

function TermsService(props) {
  const { user, logout } = useContext(AuthContext);

  const gotoTop = (e) =>{
    e.preventDefault();
    let duration = 500;
    const scrollStep = -window.scrollY / (duration / 10),
        scrollInterval = setInterval(function(){
        if ( window.scrollY !== 0 ) {
            window.scrollBy( 0, scrollStep );
        }
        else clearInterval(scrollInterval); 
    },15); 
  }
  return (
    <Fragment>
      <Container
        maxWidth={false}
        disableGutters={true}
        className={` min-h-screen bg-cover  mx-auto flex justify-between w-100 flex-col lg:flex-row relative`}
      >
        <Grid container className={`ml-0`}>
          <div className="lg:px-14 px-6">
            <div>
              <Box className="pt-8">
                <h3 className="rajdhani font-bold text-gray-800 text-lg lg:text-2xl lg:text-4xl mb-1 lg:mb-3">
                  Founder’s JourneyTM Terms of Service
                </h3>
                <h5 className="gesta text-dovegray text-base lg:text-xl mb-2 lg:mb-4 font-normal">
                  Effective Date: September 1, 2021
                </h5>
                <p className="text-sm lg:text-base text-dovegray  mb-4">
                  These Terms of Service describe the terms and conditions on
                  which RHR International LLP and any related affiliates (“RHR”,
                  “we,” “us” or “our”) offers access to and use of
                  sprint-coach.com (the “Founder’s Journey App”). The Founder’s
                  Journey App is used in connection with the consulting services
                  that RHR has been engaged to provide to the organization that
                  engaged RHR (“Client”). As an employee of a Client, you have
                  been offered access to and use of the Founder’s Journey App.
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  Before accessing and using the Founder’s Journey App, please
                  read these Terms of Service carefully because it is a legal
                  agreement between us and you. By using the Founder’s Journey
                  App, you accept without limitation or qualification, these
                  Terms of Service.
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  If you do not agree to any term of these Terms of Service,
                  please do not use the Founder’s Journey App.
                </p>
              </Box>
              {/*  */}
              <section id="section-1" className="py-5 lg:py-10">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  1. Founder’s Journey
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  The Founder’s Journey App is a tool used as part of RHR’s
                  services provided to its Clients intended to provide insight
                  with respect to your leadership strengths and weaknesses. Your
                  access to and use of the Founder’s Journey App and all content
                  displayed on or provided through the Founder’s Journey App
                  (“Content”) are subject to these Terms of Service as well as
                  all applicable laws and regulations (including all export and
                  import laws, regulations and restrictions)
                </p>
              </section>
              {/*  */}
              <section id="section-2" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  2. Information Provided by You
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  In connection with your use of the Founder’s Journey App, you
                  will provide information to RHR, including through your
                  responses to assessment questions. RHR may use any information
                  you submit to perform its services and prepare reports, as
                  well as to enhance its products and services generally. You
                  consent to the collection, use, disclosure, retention and
                  transfer of this information. To learn more about how RHR
                  collects and processes personal information please review our{" "}
                  <Link to="#">
                    <b>Privacy Policy</b>
                  </Link>
                  .
                </p>
                <h5 className="text-gray-800 font-bold rajdhani text-xl pb-4">
                  Eligibility
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  To be eligible to use the Founder’s Journey App, you must: be
                  a current employee of a Client and invited to use the
                  Founder’s Journey App, be 18 years of age or older, and not
                  currently be restricted from use of the Founder’s Journey App,
                  or otherwise prohibited from registering for an Account
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-3" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  3. Your Warranties and Undertakings
                </h5>
                <p className="text-lg text-gray-800 mb-6">
                  You represent, warrant, and covenant that:
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-2 ">
                  (1) you meet the eligibility requirement set forth above;
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-2 ">
                  (2) you are not a competitor or employed by a competitor of
                  RHR and you will not use the Founder’s Journey App for
                  competitive, illegal, or improper purposes;
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-2 ">
                  (3) you will not violate any rights of RHR, including
                  intellectual property rights such as copyright or trademark
                  rights;
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-2 ">
                  (4) your responses to the prompts, questions or requests
                  presented in the Founder’s Journey App will be truthful and
                  accurate when made;
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-2 ">
                  (5) you will not upload to, distribute, or otherwise publish
                  through the Founder’s Journey App any content which is
                  libelous, defamatory, obscene, threatening, invasive of
                  privacy or publicity rights,abusive, illegal, or otherwise
                  objectionable, or which may constitute or encourage a criminal
                  offense, violate the rights of any party or otherwise give
                  rise to liability or violate any law;
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-2 ">
                  (6) you will not upload commercial content to the Founder’s
                  Journey App;
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-2 ">
                  (7) you have full power and authority to enter into these
                  Terms of Service; and
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-2 ">
                  (8) your entry into and performance of the Terms of Service
                  does not and will not by passage of time violate any other
                  agreement to which you are a party.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-4" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  4. Founder’s Journey App Access.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  In order to access the Founder’s Journey App, we will provide
                  you with login credentials to register a Founder’s Journey App
                  account (“Account”). Notwithstanding anything to the contrary
                  herein, you acknowledge and agree that you will have no
                  ownership or other property interest in the Account, and you
                  further acknowledge and agree that all rights in and to the
                  Account are and will forever be owned by RHR. You may not
                  share the Account with anyone and are liable for all uses of
                  the Account
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  During the registration process, you will be required to
                  select a password. Your password is to be kept confidential at
                  all times and you are solely responsible for the security of
                  your password. You may not disclose your password to anyone,
                  or allow your password to be used by anyone other than
                  yourself. RHR is not responsible for any harm that may result
                  to the Account as a result of a lost or shared password. RHR
                  is not responsible in the event that the Account is “hacked,”
                  or if the Account or your computer is damaged by a virus, or
                  for any other issues with your computer or the Account
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  Accounts are not transferable or descendible by you. You may
                  not offer any Account for sale or trade, and any such offer is
                  a violation of these Terms of Service and will result in
                  termination of the Account. You acknowledge understand that
                  the Client may terminate or suspend its relationship with RHR
                  at any time and, at such time, your continued access to the
                  Founder’s Journey App may be terminated.
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  You agree to immediately notify RHR of any unauthorized use of
                  your password or Account or any other breach of security. YOU
                  ARE ENTIRELY RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY
                  OF YOUR ACCOUNT INFORMATION AND FOR ANY AND ALL ACTIVITIES
                  THAT ARE CONDUCTED THROUGH YOUR ACCOUNT.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-5" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  5. Reports.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  RHR may prepare one or more reports compiling your responses
                  together with assessments and recommendations based on your
                  responses. Any such reports delivered to you are for your
                  personal, noncommercial use and may not be distributed,
                  shared, displayed, or published by you. All report templates,
                  methodologies, and processes are proprietary to RHR, its
                  affiliates or licensors, and are protected by international
                  copyright and other intellectual property laws. No right or
                  license to these proprietary materials are expressly or
                  impliedly granted to you. Any reports delivered to you may not
                  otherwise be used, reproduced, published, distributed,
                  disclosed or shared, in whole or in part, through electronic
                  or other means except with the express written permission of
                  RHR. Each report is further subject to the usage restrictions
                  contained in the report. RHR may share the contents of any
                  report with the Client and you consent to such disclosure.
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  Reports are based on information you provide in connection
                  with your use of the Founder’s Journey App. If any information
                  about you presented by the Founder’s Journey App or
                  pre-populated by the Founder’s Journey App at the time of
                  creation of your Account or thereafter (e.g. name, country of
                  residence, title, email) is incorrect, you will promptly
                  inform RHR via the contact information below or via the
                  on-screen correction mechanism to correct the information. You
                  will immediately cease use of the Founder’s JourneyApp and not
                  resume use of the Founder’s Journey App until such information
                  is accurate and complete. RHR is not responsible for any loss
                  or damages arising from your failure to provide accurate
                  information. Contact RHR immediately if any information
                  contained in a report is inaccurate or incomplete, or if you
                  become aware of any breach or violation of the above
                  restrictions.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-6" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  6. Ownership.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  All title, ownership rights and intellectual property rights
                  in and to the Founder’s Journey App and Content (including
                  without limitation any Accounts, software, titles, computer
                  code, technology, report formats, simultaneous, methods of
                  operation, and any related documentation) are owned by RHR and
                  its affiliates or licensors. The Founder’s Journey App and
                  Content are protected by the copyright laws of the United
                  States, international copyright treaties and conventions, and
                  other laws. The Founder’s Journey App and Content may contain
                  certain licensed materials, and RHR’s licensors may protect
                  their rights in the event of any violation of the Terms of
                  Service.
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  All rights are reserved by RHR and, as applicable, RHR’s
                  licensors. You have no rights to access the Founder’s Journey
                  App or Content except as expressly provided by the terms of
                  these Terms of Service. You acknowledge that you do not
                  acquire any ownership rights in the Founder’s Journey App,
                  reports, or any of the Content therein as a result of these
                  Terms of Service or the use of the Founder’s Journey App.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-7" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  7. Submissions and Ideas
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  Any information, including but not limited to responses to
                  questions, remarks, suggestions, ideas, graphics or other
                  submissions, communicated to us through the Founder’s Journey
                  App or otherwise is deemed nonconfidential and is our
                  exclusive property. We are entitled to use any information or
                  ideas submitted for any purpose without restriction and
                  without compensation to or attribution or acknowledgement of
                  the source.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-8" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  8. Trademarks, Copyrights, and Notices.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  Except as otherwise identified, the trademarks, logos,
                  including RHR, RHR INTERNATIONAL, THE WINNING FORMULA,
                  EXECUTIVE BENCH, FOUNDER’S JOURNEY, READINESS FOR SCALE, and
                  SCALING FOR GROWTH trade names, logos, slogans and service
                  marks appearing in the Founder’s Journey App or other websites
                  controlled by RHR, and copyrights, including artwork,
                  photographs, names and other elements, whether, registered or
                  unregistered, are our property. All other logos and trademarks
                  are the property of the respective trademark owners. Such
                  trademarks and copyrights are not to be copied, reproduced,
                  published or in any way used without written permission. No
                  part of the Founder’s Journey App or Content may be published,
                  stored or transmitted in any form or means without our express
                  written permission. Any use of the Founder’s Journey App,
                  Content, or any trademarks or copyrighted materials of RHR
                  other than as permitted under this Terms of Service is
                  strictly prohibited.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-9" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  9. Hyperlinks.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  You may not establish hyperlinks to the Founder’s Journey App
                  without our prior written consent, which consent may be given
                  or withheld in our sole discretion. Framing the Founder’s
                  Journey App is expressly prohibited.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-10" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  10. DISCLAIMER.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  RHR HEREBY DISCLAIMS ALL WARRANTIES AND REPRESENTATIONS IN
                  CONNECTION WITH THE FOUNDER’S JOURNEY APP, REPORTS, AND THE
                  CONTENT, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE
                  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
                  APARTICULAR PURPOSE. WE DO NO WARRANT THAT ANY RESULTS
                  OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE OR
                  RELIABLE. WE DO NO WARRANT THAT ANY CONTENT, REPORTS, OR OTHER
                  INFORMATION WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF
                  THE FOUNDER’S JOURNEY APP WILL BE UNINTERRUPTED OR ERROR FREE.
                  WE SHALL NOT BE LIABLE, AND YOU WILL BE SOLELY RESPONSIBLE
                  FOR, FOR ANY AND ALL LOSS, OR CORRUPTION, OF DATA UPLOADED OR
                  INPUTTED BY YOU THROUGH THE USE OF THE FOUNDER’S JOURNEY APP.
                  IN NO EVENT SHALL WE HAVE LIABILITY TO YOU (INCLUDING
                  LIABILITY TO ANY PERSON OR PERSONS WHOSE CLAIM OR CLAIMS ARE
                  BASED ON OR DERIVED FROM A RIGHT OR RIGHTS CLAIMED BY YOU)
                  WITH RESPECT TO ANY AND ALL CLAIMS AT ANY AND ALL TIMES
                  ARISING FROM OR RELATED TO THE SUBJECT MATTER OF THE FOUNDER’S
                  JOURNEY APP OR THE CONTENT, IN CONTRACT, TORT, STRICT
                  LIABILITY OR OTHERWISE. SOME JURISDICTIONS MAY NOT ALLOW THE
                  EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE
                  EXCLUSIONS WILL NOT APPLY TO YOU.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-11" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  11. Security
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  You will to provide at your cost all equipment, software,
                  internet, and data access necessary to use the Founder’s
                  Journey App. Any transmission of your personal data or other
                  information is at your own risk and we expect that you will
                  use appropriate security measures to protect your personal
                  data. You also acknowledge that electronic communications and
                  databases are subject to errors, tampering and break-ins and
                  that while we will implement reasonable security precautions
                  to attempt to prevent such occurrences, we do not guarantee
                  that such events will not take place.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-12" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  12. Limitation of Liability.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  RHR SHALL NOT IN ANY EVENT BE LIABLE TO YOU OR TO ANY THIRD
                  PARTY FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL OR
                  INCIDENTAL LOSSES, DAMAGES OR EXPENSES INCLUDING FOR ANY LOST
                  PROFITS, REVENUES, BUSINESS OPPORTUNITIES OR BUSINESS
                  ADVANTAGES WHATSOEVER DIRECTLY OR INDIRECTLY RELATING TO THE
                  USE OR MISUSE OF THE FOUNDER’S JOURNEY APP, REPORTS, OR ANY
                  OTHER CONTENT PROVIDED BY RHR, THE USE OR FAILURE,
                  NON-COMPLIANCE OR LIMITED AVAILABILITY OF THE FOUNDER’S
                  JOURNEY APP, ANY OBLIGATION UNDER OR SUBJECT MATTER OF THE
                  FOUNDER’S JOURNEY APP, WHETHER SUCH CLAIM IS BASED UPON BREACH
                  OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, GROSS NEGLIGENCE,
                  STRICT LIABILITY IN TORT OR ANY OTHER THEORY OF RELIEF, OR
                  WHETHER OR NOT RHR IS INFORMED IN ADVANCE OF THE POSSIBILITY
                  OF SUCH DAMAGES.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-13" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  13. Notice to California Residents.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  BY USING THE FOUNDER’S JOURNEY APP, YOU WAIVE YOUR RIGHTS WITH
                  RESPECT TO CALIFORNIA CIVIL CODE SECTION 1542 WHICH SAYS “A
                  GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
                  DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF
                  EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE
                  MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  If the Founder’s Journey App or Content is at any time deemed
                  an electronic commercial service (as defined under California
                  Civil Code Section1789.3), California residents are entitled
                  to the following specific consumer rights information:
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  The provider of the Founder’s Journey App is:
                </p>
                <pre className="text-sm lg:text-base text-gray-700 mb-4">
                  RHR International LLP
                  <br />
                  Address: 233 South Wacker Driver, 95th Floor, Chicago, IL
                  60606
                  <br />
                  Phone: 312-924-0800
                  <br />
                </pre>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  If the Founder’s Journey App is deemed an electronic
                  commercial service, you may file a complaint regarding the
                  Founder’s Journey or to receive further information regarding
                  use of the Founder’s Journey App by sending a letter to the
                  attention of “Legal Department” at the above address.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-14" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  14. Third-Party Products and Services.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  The Founder’s Journey App and Content may contain references
                  or cross references to third party products, programs and
                  services that may not be available to you. Such references do
                  not imply that either we or such third party intends to
                  announce or make available such products, programs or services
                  in your country. Consult us or the applicable third party for
                  information regarding any such products, programs or services.
                  The Founder’s Journey App and Content may contain proprietary
                  notices and copyright information, the terms of which are
                  observed and followed. Any rights not expressly granted herein
                  are reserved.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-15" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  15. International Use.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  The Founder’s Journey App is intended for use by residents of
                  the United States, The Founder’s Journey App is not directed
                  to or meant for use by residents of the European Union (“EU”)
                  or any other country.
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  Our servers and operations are located primarily in the United
                  States and our policies and procedures are based on United
                  States law. Because of this, the following provisions apply
                  specifically to users located outside of the United States (i)
                  you consent to the transfer, storage, and processing of your
                  information to and in the United States and/or other
                  countries; (ii) if you are using the Founder’s Journey App
                  from a country embargoed by the United States, or are on the
                  United States Treasury Department’s list of “Specially
                  Designated Nationals,” you are not authorized to access or use
                  the Founder’s Journey App; and (iii) you agree to comply with
                  the laws, rules, and regulations of the United States and in
                  any country in which you reside or from which you access
                  Founder’s Journey App. The Founder’s Journey App and Content
                  are not intended for distribution to or use by any person or
                  entity in any jurisdiction or country where such distribution
                  or use would be contrary to law or regulation, or that would
                  subject us or our affiliates to any registration requirement
                  within such jurisdiction or country.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-16" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  16. Additional Terms
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  Certain features may be subject to additional or separate
                  terms (“Additional Terms”). Regardless of how the Additional
                  Terms are presented to you, you must agree to Additional Terms
                  before using the features of the services to which they apply.
                  Unless otherwise specified in Additional Terms, all Additional
                  Terms are incorporated into this Terms of Service. If you do
                  not agree to Additional Terms, then you may not use the
                  services to which they apply. This Terms of Service and
                  Additional Terms apply equally but, if any Additional Term is
                  inconsistent with any provision of this Terms of Service, the
                  Additional Term will prevail for the services to which the
                  Additional Terms apply
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-17" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  17. Changes to Terms of Service.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  These Terms of Service are subject to change from time to time
                  without notice to you. From time to time, we may: (a)
                  supplement or make changes to these Terms of Service and other
                  rules or access and use procedures, documentation, security
                  procedures and standards for equipment; (b) change the type
                  and location of Content, system equipment, facilities or
                  software; or (c) modify or withdraw the Founder’s Journey App,
                  in whole or part, or any Content. We reserve the right to
                  terminate access to the Founder’s Journey App for any reason
                  or to take other actions we believe necessary in our sole
                  discretion to complywith applicable laws and regulations
                  and/or to protect our rights, affiliates or Clients. Any
                  access or attempt to access or use the Founder’s Journey App
                  for any unauthorized or illegal purpose is strictly
                  prohibited.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-18" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  18. General.
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  These Terms of Service does not supersede or modify the terms
                  of any prior written consent you agreed to with regard to our
                  processing or use of your personal information.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-19" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  19. Governing Law
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  The use by you of the Founder’s Journey App, Content, and any
                  and all other matters related hereto, shall be governed by
                  applicable United States federal law and the laws of the State
                  of Illinois, excluding its conflict-of-law rules. You hereby
                  agree to waive any right to a jury trial in any suit arising
                  out of or relating to the Founder’s Journey App or any
                  Content. Any suit or proceeding arising out of or relating
                  hereto must be commenced within one (1) year from the date the
                  right, claim, demand or cause of action first came into being.
                  Venue for all disputes arising out of or relating to the Terms
                  of Service shall be in the state and federal courts situated
                  in Chicago, Illinois.
                </p>
              </section>
              {/*  */}

              {/*  */}
              <section id="section-20" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  20. Proper Notice
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  All notices, demands, or other communications given or made
                  under these Terms of Service shall be in writing to the
                  address set forth below:
                </p>
                <blockquote className="text-sm lg:text-base text-gray-700 mb-4 lg:pl-10">
                  RHR International LLP <br />
                  c/o Legal Department <br />
                  Willis Tower <br />
                  233 South Wacker Drive, 95th Floor <br />
                  Chicago, IL 60606 <br />
                </blockquote>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  A copy of any notice to RHR shall be sent to:
                  legal-dept@rhrinternational.com.
                </p>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  Notice shall be effective: (a) upon delivery if delivered in
                  person; (b) five (5) days after deposit in the regular mail,
                  addressed to the recipient, postage prepaid and registered
                  with return receipt requested; (c) one (1) day after deposit
                  with an express mail or overnight courier service, provided
                  that confirmation of such delivery is received; or (d) upon
                  transmission if sent via electronic mail or facsimile,
                  provided that a confirmation copy is sent via express mail or
                  overnight courier service and confirmation of such delivery is
                  received.
                </p>
              </section>
              {/*  */}
              <section id="section-21" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  21. Terminology
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  The Founder’s Journey App and the Content may use terms that
                  have technical meanings that may be different from their
                  ordinary meanings and require specialist knowledge and
                  expertise to understand properly and use effectively.
                </p>
              </section>
              {/*  */}
              <section id="section-22" className="py-2 lg:py-5">
                <h5 className="text-gray-800 font-bold rajdhani text-lg lg:text-2xl pb-4">
                  22. Questions
                </h5>
                <p className="text-sm lg:text-base text-dovegray mb-4">
                  If you have questions regarding the Founder’s Journey App, the
                  Content or these Terms of Service, contact us{" "}
                  <Link className="text-cerulean font-bold text-lg" to="#">
                    here
                  </Link>{" "}
                  or by phone at
                  <Link
                    to="tel:312-924-0800"
                    className="text-gray-800 font-bold gesta hover:text-cerulean "
                  >
                    {" "}
                    312-924-0800
                  </Link>
                </p>
              </section>
              {/*  */}
            </div>
          </div>
        </Grid>
      </Container>
      {/* scroll top */}
      <ScrollTop gotoTop={gotoTop}/>
    </Fragment>
  );
}

export default TermsService;

 

export const challenges = [
    {
        "id": 1,
        "challenge": "Continuous Self-Evolution",
        "color": 'lavenderPurple',
        "icon": `cicon-1`,
        'imgIcon': require('../assets/imgaes/stat-logo.svg').default,
        "key_attr": "A company can only grow at the speed its leader is willing to grow. Developing the self-awareness and discipline to leverage strengths and address capability gaps is a critical leadership skill for the founder/CEO to master on this journey.",
        "attributes": [
            "Actively seeks feedback and counsel to identify and address development opportunities",
            "Models a growth mindset when it comes to building range, authenticity, and vulnerability as a leader",
            "Effectively manages time, energy, and emotions to get the best out of self and others"
        ],
        "questions": {
            "main": "How would you rate your leadership on the following?",
            "count": 5,
            "quest": [
                "I actively seek feedback on areas where I can improve my leadership",
                "I have good self-awareness of my strengths and capability gaps.",
                "I effectively manage my time, emotions, and energy to get the best out of myself and others ",
                "I am sensitive to power dynamics and hold myself to a high standard of personal conduct",
                "I am aware of how my communication and influencing style impacts others"
            ],
            "secondary": "It is important to the company to strengthen my leadership in this area over the next year."
        },
        "high": "You are confident in your ability to manage yourself and bring out the best in others in diverse situations, and you are committed to leveraging your strengths and addressing gaps to become a better leader.",
        "low": "You are likely missing opportunities to gain insight into how others experience you.  Being more deliberate about leaning into your strengths and addressing your skill gap will make you a better leader.",
        "gap": "First, get curious about how others experience you as a leader. What are your core strengths? Development areas? Blind spots? How can you use these insights as fertilizer to help you grow, so that you can better guide the continued growth of your team and your company."
    },
    {
        "id": 2,
        "challenge": "Building Scalable Teams",
        'color': 'pacificBlue',
        'icon': `cicon-2`,
        'imgIcon': require('../assets/imgaes/user-icon.svg').default,
        "key_attr": "Hiring the right people with the right skills and getting the best out of them are critical founder/CEO skills. This requires the ability to hire great talent, manage team dynamics, and lead a team of experienced leaders.",
        "attributes": [
            "Makes strategic hires that add critical talent and skills to the organization before capability gaps emerge",
            "Manages individual and team dynamics by setting clear expectations, providing feedback, and holding the team accountable",
            "Establishes team purpose, role clarity, decision rights, inclusivity, and effective ways of working"
        ],
        "questions": {
            "main": "How would you rate your leadership on the following?",
            "count": 3,
            "quest": [
                "I make strong hires that add critical skills to the organization before capability gaps appear",
                "I manage individual and team dynamics by setting clear expectations, providing feedback, and holding people accountable",
                "I clearly define team purpose, role clarity, decision rights and ways of working for the team",
            ], 
            "secondary": "It is important to the company to strengthen my leadership in this area over the next year."
        },
        "high": "You understand the value of building and leveraging a high performing team as well as ensuring you have the right people in the right roles as your company scales.",
        "low": "You may not be getting the leverage and performance you need from your team due to challenging interpersonal dynamics, lack of clarity around roles, priorities and operating norms, and / or having the wrong leaders for this phase of growth.",
        "gap": "Even with the right talent at the table, optimal team performance comes from everyone being aligned around a common agenda, an animating purpose and vision and clearly defined roles and priorities, as well as a shared understanding of expectations. Where is your team falling short, and what is the sequence of actions you need to take to transform your team from good to great?"
    },
    {
        "id": 3,
        "challenge": "Active Stakeholder Management",
        'color': 'cabaret',
        'icon': `cicon-3`,
        'imgIcon': require('../assets/imgaes/hand-icon.svg').default,
        "key_attr": "Establishing partnerships with investors, board members, strategic partners, and other key stakeholders creates powerful company momentum. When managed well, these relationships become valuable strategic assets to leverage.",
        "attributes": [
            "Cultivates solid relationships with individual directors and the board as a whole",
            "Proactively invests time and energy to maintain healthy ties to key customers, suppliers, regulators, and other important stakeholders",
            "Acts with integrity to build trust and reputational equity across the entire organizational ecosystem"
        ],
        "questions": {
            "main": "How would you rate your leadership on the following?",
            "count": 4,
            "quest": [
                "I cultivate strong relationships with individual directors and the board as a whole",
                "I proactively invest time and energy to maintain the health of key customers, suppliers, and other critical strategic relationships",
                "I act with integrity to build trust and reputational equity with the public and important regulators",
                "I have a strong network of prospective investors",
            ],
            "secondary": "It is important to the company to strengthen my leadership in this area over the next year."
        },
        "high": "You invest time and energy to establish healthy relationships with important external stakeholders and are viewed positively by them as an ambassador for the company's mission and values.",
        "low": "Investing time and energy to maintain healthy relationships with your board and other critical external stakeholders as well as prospective future investors may feel like an expendable responsibility when you're busy, but these are the people you need in your corner when challenges arise, so don't neglect this essential activity.",
        "gap": "When it comes to cultivating a strong, resilient stakeholder ecosystem, you play a primary role as chief relationship officer. Consider your board, investors, strategic partners and customers, supplies, regulators to be key assets. Which ties are solid and which are weak? How can you foster healthier connections and also ensure that you are building brand equity across the ecosystem as a whole?"
    },
    {
        "id": 4,
        "challenge": "Strategic Vision",
        'color': 'blueBell',
        'icon': `cicon-4`,
        'imgIcon': require('../assets/imgaes/brain-icon.svg').default,
        "key_attr": "Effective leaders transform their vision into action by setting clear strategic priorities and clearly communicating those priorities to inspire, align, and focus the company.",
        "attributes": [
            "Defines strategic priorities that advance the company vision",
            "Communicates frequently to rally the organization and bring the vision to life",
            "Fosters broad alignment around priorities to guide decision-making, areas of focus, and resource allocation"
        ],
        "questions": {
            "main": "How would you rate your leadership on the following?",
            "count": 3,
            "quest": [
                "Our strategic vision is well-understood and clearly focuses the priorities and actions of everyone in the company (e.g., roles and responsibilities, decision making, and resource allocation are all linked to key priorities)",
                "I communicate clearly and frequently to rally the company and bring the vision to life",
                "All of my key leaders are aligned on the most important areas of focus"
            ],
            "secondary": "It is important to the company to strengthen my leadership in this area over the next year."
        },
        "high": "You appreciate the importance of aligning the organization around your animating vision for the company and ensure that strategic priorities and resource decisions consistently map to that north star.",
        "low": "Without a clearly defined north star to guide critical organizational decisions, including setting clear strategic priorities and driving accountability, you run the risk of creating an operating environment that lacks alignment and inhibits cross-functional collaboration.",
        "gap": "Ensuring that your vision remains universally understood through clearly defined and articulated strategic priorities is essential to achieving scale. If something is breaking down in that translation process, ask yourself whether there is more you and your leadership team can be doing to evangelize the vision. Or is the challenge about creating priorities that stick, which in turn allows for informed decision making and resource allocation to happen in a decentralized fashion deeper in the organization. Once you've diagnosed the root cause, you can move quickly to solution mode."
    },
    {
        "id": 5,
        "challenge": "Obsessive Product and Customer Focus",
        'color': 'fireBush',
        'icon': `cicon-5`,
        'imgIcon': require('../assets/imgaes/bookmark-icon.svg').default,
        "key_attr": "High-growth companies build value by maintaining a laser focus on their product, customers, and markets. Maintaining a strong organization-wide commitment to ongoing iteration of product and solving critical customer problems starts at the top, with the CEO.",
        "attributes": [
            "Is preoccupied by continuous product improvement and customer loyalty",
            "Relentlessly manages the company brand and reputation",
            "Is thoughtful about evolving go-to-market channels and distribution strategies to drive both short- and long-term growth"
        ],
        "questions": {
            "main": "How would you rate your company today on the following?",
            "count": 3,
            "quest": [
                "We are preoccupied with continuous product improvement",
                "We relentlessly manage the company's brand reputation with our key customers",
                "We are thoughtful about evolving go-to-market channels and distribution strategies to drive both short- and long-term growth",
            ],
            "secondary": "It is important to the company to strengthen my leadership in this area over the next year."
        },
        "high": "In addition to staying close to the pulse of your existing customers and products, you recognize the importance of remaining laser-focused on future innovations and go-to-market strategies that will broaden and diversify your customer base to drive long term growth and profitability.",
        "low": "In the absence of a fierce and visible commitment from you to keep innovating and diversifying without diluting the quality of your core offering or existing customer relationships, both short- and long-term growth imperatives will be challenging if not impossible to achieve.",
        "gap": "If you're not staying relentlessly focused on your products, customers and markets, then that mindset won't stay embedded in the DNA of your company. How can you better allocate your time to prioritize future innovation, initiatives and partnerships without takiing your finger off the pulse of existing needs and imperatives?"
    },
    {
        "id": 6,
        "challenge": "Company Rigor",
        'color': 'blueRibbon',
        'icon': `cicon-6`,
        'imgIcon': require('../assets/imgaes/building-icon.svg').default,
        "key_attr": "Scaling a company requires sufficient operational rigor to accelerate growth and mitigate execution risk. Without sacrificing agility, strong leaders build speed, efficiency, and scale through focused execution in areas like sales, operations, marketing, finance, legal, and HR.",
        "attributes": [
            "Creates the requisite processes and structures to support rapid growth without stifling creativity",
             "Manages financial levers to build a path to profitability",
            "Understands when and how to accrue organizational debt"
        ],
        "questions": {
            "main": "How would you rate your leadership on the following today?",
            "count": 3,
            "quest": [
                "We create the requisite processes and structures to support rapid growth without stifling creativity",
                "We effectively manage financial levers to build a path to profitability",
                "We effectively manage tech and organization debt as we grow",
            ],
            "secondary": "It is important to the company to strengthen my leadership in this area over the next year."
        },
        "high": "You appreciate the need to establish repeatable processes, clear swim lanes and formal operating protocols as the company scales, but without impeding the agility that's necessary to remain innovative and responsive to changing market and customer dynamics.",
        "low": "You can't achieve scale without establishing repeatable processes, clear swim lanes and formal operating protocols, so don't let these priorities languish in the name of preserving maximum agility.",
        "gap": "Calibrating the right balance between rigor and agility is both an art and a science. If you've been slow to implement processes, responsibilities and protocols that allow for greater efficiency, speed and decentralization, ask yourself how you can address this deficit in a way that doesn't stifle creativity. "
    },
    {
        "id": 7,
        "challenge": "Powered by People and Culture",
        'color': 'ronchi',
        'icon': `cicon-7`,
        'imgIcon': require('../assets/imgaes/culture-icon.svg').default,
        "key_attr": "Companies with strong values, engaged teams, and healthy cultures have a powerful advantage in the marketplace. Great leaders are intentional about embodying those values in their quest to build and maintain a company culture that inspires employees, customers, and investors alike.",
        "attributes": [
            "Lives and breathes the values and holds others accountable to those standards",
            "Ensures the culture evolves over time in a way that is consistent with the company's ongoing growth",
            "Champions diversity and inclusion and creates an environment that fosters learning and growth"
        ],
        "questions": {
            "main": "How would you rate your company today on the following?",
            "count": 4,
            "quest": [
                "I ensure that our people live and breathe the values and hold one another accountable",
                "Our culture makes it easier for us to recruit and retain great talent",
                "I champion inclusion and diversity and create an environment that fosters learning and growth",
                "We have really strong talent in all our key roles",
            ],
            "secondary": "It is important to the company to strengthen my leadership in this area over the next year."
        },
        "high": "You know that there's no better recipe for attracting and retaining top talent than a winning culture and an engaged, diverse employee population and you take your role as the lead people ambassador seriously.",
        "low": "Nothing will sink your company faster than a toxic culture, so don't relegate \"the soft stuff\" to your people org and think you've got it covered - because it all starts with you.  Model the values and reward the behavior that will make your organization the kind of company top talent wants to join.",
        "gap": "If you're not spending a significant portion of your time on people and culture, chances are you're not getting the level of employee engagement you need or the reputational edge in the marketplace you want, to fuel high-octane growth. How can you elevate this to be a top priority?"
    }
];

export const marks = [
    {
        value: 1,
        label: `Strongly Disagree`,
    },
    {
        value: 2,
        label: 'Disagree',
    },
    {
        value: 3,
        label: 'Somewhat Disagree',
    },
    {
        value: 4,
        label: 'Neutral',
    },
    {
        value: 5,
        label: 'Somewhat Agree',
    },
    {
        value: 6,
        label: 'Agree',
    },
    {
        value: 7,
        label: 'Strongly Agree',
    },
];

export const marks2 = [
    {
        value: 1,
        label: `Strongly Disagree`,
    },
    {
        value: 2,
        label: 'Disagree',
    },
    {
        value: 3,
        label: 'Somewhat Disagree',
    },
    {
        value: 4,
        label: 'Neutral',
    },
    {
        value: 5,
        label: 'Somewhat Agree',
    },
    {
        value: 6,
        label: 'Agree',
    },
    {
        value: 7,
        label: 'Strongly Agree',
    },
];

//User Defined
export const mountainsData = [
    `CONTINUOUS SELF-EVOLUTION`,
    'BUILDING SCALABLE TEAMS',
    'ACTIVE STAKEHOLDER MANAGEMENT',
    'Strategic Vision',
    'OBSESSIVE PRODUCT AND CUSTOMER FOCUS',
    'COMPANY RIGOR',
    'Powered by People and Culture'
]


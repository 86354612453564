import React from 'react';
import Box from "@material-ui/core/Box";

import {useStyles} from './GeneralInstructionCardStyle'
const GeneralInstructionCard = React.memo(function GeneralInstructionCard(props) {

    const classes = useStyles();
    
    return (
          <Box component="div" className={`bg-alabaster mt-4 md:mt-14 mb-2 md:mb-6 py-8 md:py-10 md:px-10 px-6  relative mx-auto ${classes.card}`}>
            <h4
              className="text-lg md:text-2xl text-dovegray text-gray-800 gesta">
              General instructions
            </h4>
            <p className="text-base text-black gesta pt-2.5 md:pt-6 leading-5	">
            This survey includes 24 questions divided into 7 sections. Most questions will ask you to
            rate yourself on your current leadership capabilities and the state of your company
            today. The final question in each section will ask you to anticipate how important each
            focus area will be 12 months from now. For a comprehensive overview of the Seven
            Navigation Challenges, please proceed to the definition page by clicking the button
            below.
              {/* When you take this self-audit, envision yourself and your company
              in 3-5 years. Your company has grown 10x where you are today. What will be
              different in the way the company functions? What will be different
              about your leadership? */}
            </p>
            <p className="text-base text-black gesta pt-2.5 md:pt-6 mb-0 md:mb-4 leading-5">
            Your results will be automatically sent to your email after you complete the survey.
              {/* The Seven Navigation Challenges are the development milestones each Founder needs 
              to master to get their company to scale. */}
            </p>
            <Box component="div" className="absolute top-0 left-0 overflow-x-hidden inline-flex flex-row w-full">
              <Box component="div" className="h-2 bg-lavenderPurple" style={{ width: "calc(100% / 7)" }}></Box>
              <Box component="div" className="h-2 bg-pacificBlue" style={{ width: "calc(100% / 7)" }}></Box>
              <Box component="div" className="h-2 bg-cabaret" style={{ width: "calc(100% / 7)" }}></Box>
              <Box component="div" className="h-2 bg-blueBell" style={{ width: "calc(100% / 7)" }}></Box>
              <Box component="div" className="h-2 bg-fireBush" style={{ width: "calc(100% / 7)" }}></Box>
              <Box component="div" className="h-2 bg-blueRibbon" style={{ width: "calc(100% / 7)" }}></Box>
              <Box component="div" className="h-2 bg-ronchi" style={{ width: "calc(100% / 7)" }}></Box>
            </Box>
          </Box>
    );
});

export default GeneralInstructionCard;
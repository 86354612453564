export const headCounts = [
    {
        "name": "Select",
        "value": "Select"
    },
    {
        "name": "1 - 15",
        "value": "LT15"
    },
    {
        "name": "16 - 50",
        "value": "LT50"
    },
    {
        "name": "51 - 100",
        "value": "LT100"
    },
    {
        "name": "101 - 150",
        "value": "LT150"
    },
    {
        "name": "151 - 300",
        "value": "LT300"
    },
    {
        "name": "More than 300",
        "value": "LT400"
    }
]

export const fundings = [
    {
        "name": "Select",
        "value": "Select"
    },
    {
        "name": "$0 - $6M USD",
        "value": "LT6M"
    },
    {
        "name": "$6M - $30M USD",
        "value": "LT30M"
    },
    {
        "name": "$31M - $50M USD",
        "value": "LT50M"
    },
    {
        "name": "$51M - $100M USD",
        "value": "LT100M"
    },
    {
        "name": "$101M - $300M USD",
        "value": "LT300M"
    },
    {
        "name": "More than $300M",
        "value": "LT400M"
    }
]

export const countries = [
    "USA", "UK", "Spain", "Germany"
]

export const counties = [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]

export const industrySectors = [
    {
        "name": "Adtech",
        "value": "AD"
    },
    {
        "name": "Artificial intelligence and machine learning (AI/ML)",
        "value": "AIML"
    },
    {
        "name": "Augmented reality and virtual reality (AR/VR)",
        "value": "ARVR"
    },
    {
        "name": "Beauty and Wellness",
        "value": "BW"
    },
    {
        "name": "Big Data",
        "value": "BD"
    },
    {
        "name": "Biotech",
        "value": "BT"
    },
    {
        "name": "Cannabis",
        "value": "CB"
    },
    {
        "name": "Cloudtech and DevOps",
        "value": "CD"
    },
    {
        "name": "Construction technology",
        "value": "CT"
    },
    {
        "name": "Cybersecurity",
        "value": "CS"
    },
    {
        "name": "Digital health",
        "value": "DH"
    },
    {
        "name": "Ecommerce/marketplace",
        "value": "EM"
    },
    {
        "name": "Edtech",
        "value": "ED"
    },
    {
        "name": "eSports (or sport tech)",
        "value": "ST"
    },
    {
        "name": "Femtech",
        "value": "FMT"
    },
    {
        "name": "Fintech (including Cryptocurrency and Blockchain)",
        "value": "FCB"
    },
    {
        "name": "Foodtech",
        "value": "FT"
    },
    {
        "name": "Gaming",
        "value": "GM"
    },
    {
        "name": "Healthtech",
        "value": "HT"
    },
    {
        "name": "HRtech",
        "value": "HR"
    },
    {
        "name": "Insurtech",
        "value": "IT"
    },
    {
        "name": "Impact investing",
        "value": "II"
    },
    {
        "name": "Infrastructure",
        "value": "IF"
    },
    {
        "name": "Legal tech",
        "value": "LT"
    },
    {
        "name": "Manufacturing",
        "value": "MF"
    },
    {
        "name": "Marketing tech",
        "value": "MT"
    },
    {
        "name": "Real estate tech",
        "value": "RET"
    },
    {
        "name": "Restaurant tech",
        "value": "RT"
    },

    {
        "name": "Robotics and drones",
        "value": "RD"
    },
    {
        "name": "Software as a service (SaaS)",
        "value": "SAAS"
    },
    {
        "name": "Space tech",
        "value": "SPT"
    },
    {
        "name": "Supply chain technology",
        "value": "SCT"
    },
    {
        "name": "Technology, media and telecommunications (TMT)",
        "value": "TMT"
    },
    {
        "name": "Other - not listed",
        "value": "OTR"
    }
]